.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 100;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  z-index: 100;
  transition: opacity 300ms linear;
}

.fade-exit {
  opacity: 1;

}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms linear;
} 