
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  color: #000!important;
}
a:hover {
   color: #888!important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.introdiv {
  margin:0;
  margin-top: calc(25vh - 90px);

  height:100%
}
.conndiv{
  margin:0;
  margin-top: calc(25vh - 50px);

  height:100%
}
.connicon {
  border: 0.3vw solid #ddd;
  width: 35%;
  height: 35%;
  border-radius: 50%;
  font-size: 18px;
  
  /* padding-top: 9px; */
  color: #999;
}
.prodicon {
  border: 0.3vw solid #ddd;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  font-size: 18px;
  
  /* padding-top: 9px; */
  color: #999;
}
.backicon {
  border: 0.3vw solid #ddd;
  width: 10%;
  height: 10%;
  border-radius: 50%;
  font-size: 18px;
  
  /* padding-top: 9px; */
  color: #999;
}
.rs-icon.fill-color use{
  color: black;
}
.introme {
  border: 0.5vw solid #ddd;
  width: 40%;
  height: calc(40% - 100px);
  border-radius: 50%;
  font-size: 18px;
  
  /* padding-top: 9px; */
  color: #999;
}

.imgfade {
  opacity: 1;
  -webkit-transition: opacity 2s linear;
}
/* div {
  font-family: "Century Gothic Std", Arial, sans-serif;
  src: local('Century Gothic Std'), url(../fonts/Century_Gothic_Std_Regular.otf) format('otf');
} */

h1, h2, h3, h4, h5, h6, p, div {
  font-family: "Century Gothic Std", Arial, sans-serif;
  src: local('Century Gothic Std'), url(../fonts/Century_Gothic_Std_Regular.otf) format('otf');
}

/* .preservationMain {

} */

@keyframes perservationExpanding {
  0% {
    margin-left: 100%;
  }
  50%{
    margin-left: 50px;
    width: 100px;
    height: 790px;
  }
  100% {
    width: calc(100% - 60px);
    height: 790px;
    
  }
}

@keyframes perservationLeftCover {
  0% {
    /* margin-left: 100%; */
    width: calc(100% - 60px);
  }
  100%{
    /* margin-left: 50px; */
    width: 0px;
    /* height: 790px; */
  }
  /* 100% {
    width: calc(100% - 60px);
    height: 790px;
    
  } */
}

@keyframes perservationRightCover {
  0% {
    width: 0px;
    right:0px;
  }
  50%{
    width: calc(100% - 260px);
    right:0px;
    /* height: 790px; */
  }
  100% {
    width: 0px;
    
  }
}


@media all and (max-width:450px) and (orientation:portrait){
  .jktext {
    font-size:10px;
  }
  .rs-nav-item-content{
    font-size:10px!important;
  }
  .react-images__view-image {
    max-height: 100vh!important;
    padding-bottom: 300px;
  }
  .react-images__view{
    padding-top:150px;
  }
}


@media all and (orientation:landscape)and (max-height:800px){
  .jktext {
    font-size:calc(0.5vw + 7px);
  }
  .react-images__view-image {
    max-height: 100vh!important;
    padding-bottom: 150px;
  }
  .react-images__view{
    padding-top:150px;
  }
}

@media all and (orientation:landscape)and (min-height:800px){
  .jktext {
    font-size:calc(0.5vw + 7px);
  }
  .react-images__view-image {
    max-height: 100vh!important;
    padding-bottom: 300px;
  }
  .react-images__view{
    padding-top:150px;
  }
}

@media all and (min-width:450px) and (max-width:800px) and (orientation:portrait){
  .jktext {
    font-size:calc(0.7vw + 7px);
  }
  .rs-nav-item-content{
    font-size:calc(0.7vw + 7px)!important;
  }
  .react-images__view-image {
    max-height: 70vh!important;
    padding-bottom: 200px;
  }
}

@media all and (min-width:1100px) and (orientation:portrait){
  .jktext {
    font-size:12px;
  }
  .react-images__view-image {
    max-height: 100vh!important;
    padding-bottom: 300px;
  }
}


@media all and (min-width:800px) and (max-width:1100px) and (orientation:portrait){
  .jktext {
    font-size:12px;
  }
  .react-images__view-image {
    max-height: 70vh!important;
    padding-bottom: 200px;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Century Gothic Std';
  src: url(../fonts/Century_Gothic_Std_Regular.otf);
}

@import '~rsuite/dist/styles/rsuite-default.css'
